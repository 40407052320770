<template>
  <section id="feather-icons">
    <div class="icon-search-wrapper my-3 mx-auto">
      <b-form-group>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="icons-search"
            v-model="seachQuery"
            autocomplete="new-password"
            :placeholder="$t('Search')"
          />
        </b-input-group>
      </b-form-group>
    </div>
    <div id="icons-container">
      <div class="d-flex flex-wrap">
        <b-card
          v-for="icon in filterIconsFal"
          :key="'fal-' + icon"
          v-b-tooltip.hover.top="icon"
          class="icon-card cursor-pointer text-center mb-2 mx-50"
          @click="copyIconName('fal', icon)"
        >
          <div class="icon-wrapper">
            <i :class="'fa-light fa-2x fa-' + icon" />
          </div>
          <b-card-text class="icon-name text-truncate mb-0 mt-1">
            <small>{{ icon }}</small>
          </b-card-text>
        </b-card>
      </div>
      <b-pagination
        v-model="currentPageFal"
        :total-rows="rowsFal"
        :per-page="perPage"
        size="lg"
      />
    </div>
  </section>
</template>

<script>
import {
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BCard,
  BPagination,
  BCardText,
  VBTooltip,
} from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import fa from '@core/assets/fonts/icons/metadata/icons.json'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import { showToast } from '@/store/functions'

library.add(fas, far, fab)
export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,

    BInputGroup,
    BCardText,
    BPagination,
    BInputGroupPrepend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fas,
      far,
      fab,
      library,
      json: fa,
      iconsList: [],
      seachQuery: '',
      iconSolid: [],
      iconRegular: [],
      iconLight: [],
      iconDuotone: [],
      iconThin: [],
      iconsFas: [],
      iconsFar: [],
      iconsFab: [],
      perPage: 60,
      currentPageFab: 1,
      currentPageFas: 1,
      currentPageFar: 1,
      currentPageFal: 1,
      rowsFal: null,
      rowsFab: null,
      rowsFar: null,
      rowsFas: null,
      tabs: null,
    }
  },
  computed: {
    filterIconsFal() {
      const start = this.perPage * (this.currentPageFal - 1)
      const end = this.perPage * this.currentPageFal

      let filtrado = []
      filtrado = this.iconLight.slice()

      const seachQueryIcon = this.seachQuery.toLowerCase()
      filtrado = filtrado.filter(item => item.toLowerCase().includes(seachQueryIcon))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rowsFal = filtrado.length

      return filtrado.slice(start, end)
    },
    filterIconsFas() {
      const start = this.perPage * (this.currentPageFas - 1)
      const end = this.perPage * this.currentPageFas

      let filtrado = []
      filtrado = this.iconsFas.slice()

      const seachQueryIcon = this.seachQuery.toLowerCase()
      filtrado = filtrado.filter(item => item.toLowerCase().includes(seachQueryIcon))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rowsFas = filtrado.length

      return filtrado.slice(start, end)
    },
    filterIconsFar() {
      const start = this.perPage * (this.currentPageFar - 1)
      const end = this.perPage * this.currentPageFar

      let filtrado = []
      filtrado = this.iconsFar.slice()
      const seachQueryIcon = this.seachQuery.toLowerCase()
      filtrado = filtrado.filter(item => item.toLowerCase().includes(seachQueryIcon))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rowsFar = filtrado.length

      return filtrado.slice(start, end)
    },

    filterIconsFab() {
      const start = this.perPage * (this.currentPageFab - 1)
      const end = this.perPage * this.currentPageFab

      let filtrado = []
      filtrado = this.iconsFab.slice()
      const seachQueryIcon = this.seachQuery.toLowerCase()
      filtrado = filtrado.filter(item => item.toLowerCase().includes(seachQueryIcon))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rowsFab = filtrado.length

      return filtrado.slice(start, end)
    },
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      const fasData = Object.keys(library.definitions.fas)
      const farData = Object.keys(library.definitions.far)
      const fabData = Object.keys(library.definitions.fab)

      this.iconsFas = []
      this.iconsFar = []
      this.iconsFab = []

      const resultFas = new Set(fasData)
      const resultFar = new Set(farData)
      const resultFab = new Set(fabData)

      this.iconsFas = [...resultFas]
      this.iconsFar = [...resultFar]
      this.iconsFab = [...resultFab]

      this.iconLight = []

      Object.entries(this.json).forEach(element => {
        if (element[1].styles != null) {
          if (element[1].styles.includes('light')) {
            this.iconLight.push(element[0])
          }
        }
      })
    },
    changeName(value) {
      const res = value.toLowerCase()
      return res.replace(' ', '-')
    },
    copyIconName(style, icon) {
      showToast(icon, 1, this)
      this.$emit('iconFontAwesome', [style, `fa-${icon}`])
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
