import { render, staticRenderFns } from "./menuView.vue?vue&type=template&id=35e68e3a&scoped=true"
import script from "./menuView.vue?vue&type=script&lang=js"
export * from "./menuView.vue?vue&type=script&lang=js"
import style0 from "./menuView.vue?vue&type=style&index=0&id=35e68e3a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e68e3a",
  null
  
)

export default component.exports